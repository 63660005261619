import { createStore } from "vuex";

export default createStore({
  state: {
    access_token: "",
  },
  getters: {},
  mutations: {
    SET_TOKEN: (state, access_token) => {
      state.access_token = access_token;
    },
  },
  actions: {
    setToken: ({ commit }, access_token) => {
      commit("SET_TOKEN", access_token);
    },
  },
  modules: {},
});
