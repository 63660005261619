import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "/login",
    component: () => import("@/views/Login/Login"),
  },
  {
    path: "/creditSuggest",
    name: "/creditSuggest",
    component: () => import("@/views/CreditSuggest/CreditSuggest"),
  },
  {
    path: "/result",
    name: "/result",
    component: () => import("@/views/Result/Result"),
  },
  {
    path: "/freightLedger/:voucherNo",
    name: "/freightLedger",
    component: () => import("@/views/FreightLedger/FreightLedger"),
  },
  {
    path: "/mobileHome",
    name: "/mobileHome",
    component: () => import("@/views/MobileHome/MobileHome"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const access_token = store.state.access_token;

  if (process.env.NODE_ENV === "development") {
    next();
  } else if (!access_token && to.path !== "/login") {
    router.push({ path: "/login", query: { back: to.fullPath } });
  }

  next();
});

export default router;
